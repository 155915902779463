import React from "react"
import { Link } from "gatsby"
import * as styles from "./BusinessUsecase.module.scss"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

const data = [
  {
    images:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_3_91d687e871.webp",
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Easyfill_6fa9ac967c.svg",
    title: "AI-Powered Smart Forms Platform",
    head: "Fill forms 20x faster with Easyfill.ai – accurate & efficient!",
    button: "/ai-portfolio/easy-fill/",
    button2: "Development",
  },
  {
    images:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_b1b3b89298.webp",
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Predicto_cedb8f1496.svg",
    title: "AI-Powered Stock Forecasting",
    head: "Predicto AI predicts stocks & automates trading in real-time.",
    button: "/portfolio/predicto/",
    button2: "AI-Powered Precision",
  },
  {
    images:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_4_9c4b3a0f80.webp",
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Liveness_6ec13233d7.svg",
    title: "AI-Powered Liveness Detection And Face Comparison",
    head: "InvoZone AI boosts KYC with secure liveness & face checks.",
    button: "/portfolio/ai-powered-liveness/",
    button2: "Resource Augmentation",
  },
  {
    images:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_5_a75a7c7d17.webp",
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Weapon_Detection_a0efbfa528.svg",
    title: "AI Powered Weapon Detection System",
    head: "WeaponWatch AI detects weapons via CCTV for instant alerts.",
    button: "/portfolio/weapon-detection-system/",
    button2: "Application Modernisation",
  },
]

const WorkPortfolio = ({ strapiData, projectEng }) => {
  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <Row>
            <Col
              xs={strapiData?.subTitle ? 6 : 8}
              sm={strapiData?.subTitle ? 6 : 8}
              md={strapiData?.subTitle ? 6 : 8}
              lg={strapiData?.subTitle ? 5 : 8}
            >
              <div className={styles.headSearch}>
                <p
                  className={styles.heading}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.title,
                  }}
                />
              </div>
            </Col>
            {strapiData?.subTitle && (
              <Col xs={12} sm={12} md={6} lg={6}>
                <p className={styles.description}>{strapiData?.subTitle}</p>
              </Col>
            )}
          </Row>
          <Row className="align-items-center gap-30">
            <Col lg={12} md={12} sm={12} xs={12}></Col>

            {data?.map((e, i) => (
              <Col
                lg={6}
                md={12}
                sm={12}
                xs={12}
                key={i}
                className={styles.rowGap}
              >
                <div className={styles.caseImages}>
                  <img
                    src={e?.images}
                    alt="client logos"
                    decoding="async"
                    loading="lazy"
                    className={styles.clientImgs}
                  />
                  <div className={styles.content}>
                    <p>{e?.title}</p>
                    <h3>{e?.head}</h3>
                    <div className={styles.logos}>
                      <hr className={styles.line} />
                      <div className={styles.logoBtn}>
                        <img
                          src={e?.logos}
                          alt="client logos"
                          decoding="async"
                          loading="lazy"
                          className={styles.logoImages}
                        />
                      </div>
                    </div>
                  </div>

                  <Link to={e?.button} className={styles.btn2}>
                    <img
                      src="https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_Stroke_123158fa91.png"
                      decoding="async"
                      loading="lazy"
                      className={styles.arrowImage}
                    />
                  </Link>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default WorkPortfolio
